@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.previewGroupLink {
  @include desktop-body-font;
  color: $color-interactive;
  display: flex;
  justify-content: space-between;
  max-width: max-content;
  gap: 4px;
  text-decoration: underline;
}
