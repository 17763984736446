@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  margin-top: 45px;
  width: 100%;
  text-align: center;

  .table {
    [class^='table-module_toolbarContainer'] {
      height: 60px;
      box-sizing: border-box;
    }

    .toolbarContainer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      align-items: flex-end;
      height: 44px;

      .searchForm {
        width: 280px;
        text-align: left;
      }
    }
  }

  [class^='table'] {
    :global {
      .cds--data-table {
        th:nth-child(1) {
          width: 35%;
          text-align: start;
        }

        td {
          padding-left: 24px !important;
        }
      }
    }
  }
}
