@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding: 40px 40px 32px 280px;

  .heading {
    color: $color-front;
    @include desktop-headline-medium-font;
    line-height: 28px;
    padding: 16px 0;
    margin-bottom: 16px;
  }

  .disabledCell {
    color: $color-disabled-front;
  }

  .emptyTableContent {
    margin-top: 16px;
    max-width: 300px;
    @include desktop-body-font;
  }
}

.toolbarRight {
  display: flex;
  justify-content: space-between;
  width: 450px;
  height: 100%;
  align-items: center;
}

.toolbarLeft {
  display: flex;
  align-items: center;
  height: 100%;
}

.toolbarAddButton {
  .plusIcon {
    width: 16px;
    height: 16px;
  }
}

.toolbarCompanySelector {
  width: 360px !important;
  min-width: 360px !important;
}

.toolbarSearch {
  width: 280px;
}

.link {
  color: $color-interactive;
  text-decoration: underline;
}

.editButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
