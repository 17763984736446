@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.modalBtn {
  background-color: $color-red-front !important;
  &:hover {
    background-color: $red !important;
  }
}
.modalContent {
  color: $color-front;
}

.modalContentRow {
  display: block;
  padding-bottom: 16px;

  span {
    font-weight: 500;
  }
}
