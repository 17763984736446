.container {
  padding: 30px 30px 30px 260px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterModal {
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 24px;

    > :first-of-type {
      margin-right: auto;
    }
  }
}
