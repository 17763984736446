.icon {
  width: 16px;
  height: 16px;
}

.softSkillRelationContainer {
  display: flex;
  min-width: 95%;
  width: 95%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.softSkillRelationsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
