@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding: 40px 40px 32px 40px;

  .heading {
    color: $color-front;
    @include desktop-headline-medium-font;
    line-height: 28px;
    padding: 16px 0;
    margin-bottom: 16px;
  }

  .companyDropdown {
    width: 360px !important;
    margin-bottom: 32px;
  }

  .tabs {
    :global(.cds--tab-content) {
      padding: 32px 0;
    }
  }

  .disabledCell {
    color: $color-disabled-front;
  }

  .emptyTableContent {
    margin-top: 16px;
    max-width: 300px;
    @include desktop-body-font;
  }

  .filterBtn[aria-disabled='true'] {
    svg {
      path {
        stroke: $color-disabled-front;
      }
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalParagraph {
    @include desktop-body-font;
  }
}

.toolbarSearch {
  width: 280px;
  margin-top: 10px;
}

.link {
  color: $color-interactive;
  text-decoration: underline;
}

.modalContentFiltering {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .fieldLabel {
    margin-bottom: 8px;
    @include desktop-body-medium-font;
  }

  .dateFieldsWrapper {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;

  .modalFooterBtns {
    display: flex;
    gap: 10px;

    .negativeBtn {
      background-color: $color-red-front;

      // temporary solution - until full lib update
      &:hover {
        background-color: #b21212 !important;
      }
    }
  }
}
