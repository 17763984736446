@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  .form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 16px;
  }

  .sectionTitle {
    @include desktop-title-font;
    color: $color-front;
    margin-bottom: 16px;
  }

  .row {
    display: flex;
    gap: 24px;
    height: 60px;
  }

  .small {
    height: 44px;
    margin-bottom: 16px;
  }

  .reportsAccess {
    margin-top: 16px;
  }

  .nameField {
    flex: 7;
  }

  .selectField {
    flex: 3;
  }

  .startTimeDatepicker {
    min-width: 150px;
  }

  .endTimeCheckbox {
    min-width: 100px;
    display: flex;
    align-items: center;
  }

  .checkboxesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 24px;
    max-width: 405px;
    padding: 5px 0px 24px;
  }
}
