.softSkillTraitsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 10px;
}

.editSoftSkillTraitContainer {
  display: flex;
  min-width: 95%;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.editSoftSkillTraitSelector {
  max-width: 40%;
  width: 35%;
}

.editSoftSkillTraitWeight {
  max-width: 15%;
  width: 15%;
}

.editSoftSkillTraitOrientation {
  max-width: 25%;
}

.addSoftSkillTraitButtonContainer {
  display: flex;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
}
