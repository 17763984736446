@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.buttonContainer {
  display: flex;
  justify-content: right;
  margin: 0px 20px 0px 20px;
  gap: 20px;
}

.button {
  width: 100px;
}

.uneditableFields {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0px 20px;
  border-bottom: solid thin $color-disabled-back;

  .idField {
    width: 50px;
  }

  .slugField {
    width: 300px;
  }

  .granularityField {
    width: fit-content;
  }

  .ownerField {
    width: fit-content;
  }
}

.editableFieldsContainer {
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  border-bottom: solid thin $color-disabled-back;
}

.softSkillDependenciesContainer {
  display: flex;
  justify-content: space-evenly;
}

.softSkillTraitsContainer {
  width: 65%;
  margin: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.softSkillRelationsContainer {
  width: 35%;
  margin: 20px 0px 0px 20px;
  border-right: solid thin $color-disabled-back;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h2 {
  margin-bottom: 20px;
  justify-self: left;
  align-self: flex-start;
}
