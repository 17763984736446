@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding-top: 30px;
  padding-left: 15px;

  p {
    @include desktop-caption-font;
    color: $black-54;
  }

  .form {
    display: flex;
    gap: 16px;
    margin-top: 4px;
  }
}
