@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;

  .title {
    @include desktop-headline-medium-font;
    color: $color-front;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .buttonsContainer {
    display: flex;
    gap: 16px;
  }

  .submitButton {
    max-width: 200px;
  }
}
