@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-request {
  padding: 32px 108px;

  .button {
    .button__content {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__back-button {
    .project-request__back-button-icon {
      margin-top: -3px;
    }
  }

  .loader {
    height: 30vh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-right {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  .project-request-update {
    max-width: 978px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__company-sign {
      display: flex;
      justify-content: center;
      margin: 40px 0;
    }
  }

  .project-name-field {
    display: flex;
    flex-direction: row;

    .sz-text-input {
      height: 30px;

      &__input-element {
        @include desktop-headline-medium-font;
      }
    }
  }

  .project-request-status-select {
    width: 252px !important;

    .sz-select__single-value {
      margin: 0px !important;
    }

    .sz-select__control {
      min-height: 25px !important;
      border: 0 !important;
    }

    .sz-select__value-container {
      padding: 0 0 4px 0 !important;
      @include desktop-body-font;
    }

    .sz-select__input {
      caret-color: transparent !important;
    }

    .sz-select__option {
      padding: 0 !important;
      min-height: 28px;

      &--is-selected {
        background-color: $white !important;
      }

      &--is-focused {
        background-color: $white !important;
      }
    }

    &__option-label,
    &__selected-value-label {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;
      border-radius: 4px;
      min-height: 28px;

      &_color_green {
        background-color: $green-10;
        color: $green;
      }

      &_color_purple {
        background-color: $purple-10;
        color: $purple;
      }

      &_color_grey {
        background-color: $lightgrey;
        color: $grey;
      }

      &_color_blue {
        background-color: $blue-10;
        color: $blue;
      }
    }

    .sz-select__menu-list {
      padding: 20px !important;
      gap: 16px !important;
      display: flex;
      flex-direction: column;
    }
  }

  .contacts-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
    max-height: 400px;
    overflow: auto;

    .contacts-list-item {
      display: flex;
      gap: 24px;
      align-items: center;

      .contacts-list-item-field {
        width: 100%;
        border-bottom: 1px solid $black-16;
        cursor: not-allowed;

        &__content-wrapper {
          display: flex;
          flex-direction: row;
          gap: 8px;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &__content,
        &__note {
          @include desktop-body-font;
          white-space: nowrap;
        }

        &__note {
          color: $black-38;
        }

        &__title {
          @include desktop-caption-font;
        }
      }

      &__role-select {
        max-width: 150px;
      }

      &__delete-button {
        &:hover {
          .project-request-create-form__delete-icon {
            width: 16px;
            height: 16px;

            path {
              fill: $green;
            }
          }
        }
      }

      &__delete-icon {
        width: 16px;
        height: 16px;

        path {
          fill: $black-54;
        }
      }
    }
  }

  .documents-list {
    margin-top: 40px;
    display: flex;
    gap: 20px;

    .documents-list-item {
      display: flex;
      align-items: center;
      gap: 8px;

      &__delete-button {
        padding: 8px 0;
      }

      &__delete-icon {
        height: 16px;
        width: 16px;

        path {
          fill: $black-54;
        }
      }
    }
  }

  .project-request-actions {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;

    &__add-icon {
      width: 16px;
      height: 16px;

      path {
        fill: $black;
      }
    }
  }

  .project-and-approval-actions {
    margin-top: 40px;
    display: flex;
    gap: 16px;

    &__pencil-icon {
      width: 16px;
      height: 16px;

      path {
        fill: $white;
      }
    }

    .project-request-approvals-preview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__text,
      &__link {
        @include desktop-caption-font;
        color: $grey;
      }

      &__link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.project-request-loader-wrapper {
  .loader {
    height: 30vh;
  }
}
