.container {
  max-width: 600px;
  margin: auto;

  .stagesContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
