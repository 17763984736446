.container {
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  .row {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  }
}
