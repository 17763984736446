@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.buttonContainer {
  display: flex;
  justify-content: right;
  margin: 0px 20px 0px 20px;
  gap: 20px;
}

.button {
  width: 100px;
}

.inlineNotification {
  margin: 20px 20px 0px 20px;
  display: flex;
  align-items: center;
  border-bottom: solid thin $color-disabled-back;
}

.editableFieldsContainer {
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  border-bottom: solid thin $color-disabled-back;
}

.fieldWithWarning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  .inlineWarning {
    display: flex;
    align-items: center;
    height: 100%;
    width: 700px;
    border-bottom: solid thin $color-disabled-back;
    margin: 0px 10px 20px 10px;
  }
}
