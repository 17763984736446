@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  @include desktop-caption-font;
  color: $black-54;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 4px;
    height: 16px;
  }

  .disabledLabel {
    color: $color-disabled-front;
  }

  .radioGroup {
    display: flex;
    gap: 4px;
  }
}
.modalContainer {
  .label {
    @include desktop-body-font;
    color: $black-54;
  }

  .cognitiveTestsBlockedValue {
    @include desktop-body-medium-font;
    color: $color-front;
    margin-left: 8px;
  }

  .cognitiveTestsBlockedInlineNotification {
    max-width: 352px;
    margin-top: 10px;
  }

  .actionsContainer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
