@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding-top: 50px;

  .title {
    @include desktop-headline-medium-font;
    margin-bottom: 40px;
  }

  .body {
    display: flex;
    gap: 60px;

    .form {
      flex: 0 1 600px;

      .btnWrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 40px;
      }
    }

    .info {
      flex: 0 1 532px;
    }
  }
}
