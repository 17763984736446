@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding: 40px 40px 32px;

  .heading {
    color: $color-front;
    @include desktop-headline-medium-font;
    line-height: 28px;
    padding: 16px 0;
    margin-bottom: 16px;
  }

  .disabledCell {
    color: $color-disabled-front;
  }

  .emptyTableContent {
    margin-top: 16px;
    max-width: 300px;
    @include desktop-body-font;
  }
}

.toolbarSearch {
  width: 280px;
  margin-top: 10px;
}

.link {
  color: $color-interactive;
  text-decoration: underline;
}
